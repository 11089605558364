.jumbotron {
  position: relative;
  overflow: hidden;
  height: auto; /* Adjust as needed to fit your design */
  background-color: white;
  z-index: -5;
  padding: 2em 1em; /* Add some padding for smaller screens */
}

.jumbotron::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse 80% 110% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%);
  z-index: -2; /* Ensure it's behind the particles */
}

.jumbotron .particles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Ensure particles are behind the content but above the gradient background */
}

.jumbotron h1 {
  font-family: 'Integral CF', sans-serif;
  font-weight: 900; /* Use heavy weight */
  font-size: 7vw; /* Use viewport width for responsive text */
  margin: 0;
  text-align: center; /* Center the text */
}

.jumbotron p {
  color: #B0B0B0;
  font-size: 1.2em; /* Relative units for better scalability */
  margin: 1em 0;
  text-align: center; /* Center the text */
}

.main-title {
  max-width: 80%; /* Adjust as needed for responsiveness */
  margin: 2rem auto 0; /* Center the container and adjust margin-top */
}

.sub-title {
  max-width: 60%; /* Adjust as needed for responsiveness */
  margin: 1.5rem auto 0; /* Center the container and adjust margin-top */
}

@media (max-width: 1200px) {
  .jumbotron h1 {
    font-size: 12vw; /* Adjust for medium screens */
  }
}

@media (max-width: 768px) {
  .jumbotron h1 {
    font-size: 15vw; /* Adjust for small screens */
  }

  .jumbotron p {
    font-size: 1em; /* Adjust paragraph size */
  }
}

@media (max-width: 480px) {
  .jumbotron h1 {
    font-size: 19vw; /* Adjust for very small screens */
  }

  .main-title {
    max-width: 100%; /* Adjust for very small screens */
  }

  .sub-title {
    max-width: 90%; /* Adjust for very small screens */
  }

  .jumbotron p {
    font-size: 0.9em; /* Adjust paragraph size */
  }
}
