.contact-section {
  padding: 2em 1em;
  width: 100%;
}

.contact-content {
  max-width: 60%;
  margin: 0 auto;
}
.email-form{
  max-width: 60%;
}

.contact-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.email-form .form-control {
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.email-button {
  background-color: #1A1A1A;
  color: #B0B0B0;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 1.5rem;
  }

  .email-form .form-control {
    font-size: 0.875rem;
    max-width: 100%;
  }

  .email-button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  .contact-content {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 0;
  }

  .contact-title {
    font-size: 0.25rem;
  }

  .email-form .form-control {
    font-size: 0.75rem;
  }

  .email-form{
    max-width: 100%;
  }

  .email-button {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }

  .contact-content {
    max-width: 90%;
    padding: 0.5rem;
  }
}

.title{
  font-size: 3rem;
}