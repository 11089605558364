/* src/pages/JobsPage.css */

.jobs-page {
    padding: 20px;
    color: #fff;
    font-family: 'Onest', sans-serif;
}

.jobs-page h1 {
    font-family: 'Integral CF', sans-serif;
    font-size: 3rem;
    margin-bottom: 20px;
}

.jobs-page h2 {
    font-family: 'Integral CF', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.filter-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.job-search,
.filters {
    flex: 1;
    margin-right: 20px;
}

.job-selection {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between the dropdowns */
    width: 100%; /* Ensure the job selection takes full width */
}

.job-selection .dropdown-filters {
    width: 100%; /* Ensure each filter takes full width */
}

.job-selection select,
.dropdown-filters select {
    width: 100%; /* Ensure the dropdown itself takes the full width */
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1rem;
}

.filters {
    font-family: 'Onest', sans-serif;
    width: 100%; /* Ensure filters section takes full width */

}

.filters label {
    margin-right: 20px;
    display: block;
}

.filters select,
.filters input[type="text"] {
    padding: 8px;
    font-size: 1rem;
    margin-top: 5px;
    width: 100%; /* Ensure the filters take the full width */
}

.badge-filters {
    margin-top: 10px;
}

.badge-filters .badge-unselected {
    background-color: #6c757d;
    color: white;
    margin-right: 10px;
    cursor: pointer;
}

.badge-filters .badge-selected {
    background-color: #007bff;
    color: white;
    margin-right: 10px;
    cursor: pointer;
}



.jobs-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.job-listings {
    margin-top: 20px;
}

.dropdown-box {
    width: 100%; /* Ensure this takes the full width of the container */
}
