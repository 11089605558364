.media-slider-container {
    width: 100%;
    height: 100%; /* Set the height as needed */
}

.media-container {
    position: relative;
    width: 100%;
    min-height: 300px; /* Ensure a minimum height */
    height: auto; /* Allow the height to adjust as needed */
    overflow: hidden;
}

.media-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure media is fully shown without cropping */
}
