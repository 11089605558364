/* General styles for the popup */
.modal-content {
  border-radius: 0.625rem; /* Rounded corners for the modal */
  padding: 1.25rem; /* Padding inside the modal */
  box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.5); /* Shadow for the modal */
}

/* Specific styles for the slider container */
.slider-container {
  width: 100%;
  max-width: 50rem; /* Maximum width for the slider (800px equivalent) */
  margin: 0 auto; /* Center the slider */
}

/* Styling the images inside the slider */
.slider-container .slick-slide img {
  width: 100%;
  height: 25rem; /* Set a fixed height for the images (400px equivalent) */
  border-radius: 0.5rem; /* Rounded corners for the images */
  object-fit: contain; /* Ensure the entire image fits within the box */
}

/* Adjusting padding for the slider */
.slider-container .slick-list {
  padding: 0 0.625rem; /* Adjust padding as needed (10px equivalent) */
}

/* Styling for the slider navigation arrows */
.slider-container .slick-prev, .slider-container .slick-next {
  width: 1.875rem; /* 30px equivalent */
  height: 1.875rem; /* 30px equivalent */
  z-index: 1;
}

.slider-container .slick-prev:before, .slider-container .slick-next:before {
  font-size: 1.875rem; /* 30px equivalent */
  color: #333; /* Arrow color */
}

/* Media queries for large devices */
@media (min-width: 48rem) { /* 768px equivalent */
  .modal-content {
    padding: 2.5rem; /* Increased padding for large devices (40px equivalent) */
    max-width: 50rem; /* Set a maximum width for the modal (800px equivalent) */
    margin: auto; /* Center the modal */
  }

  .media-container {
    min-height: 300px; /* Set a minimum height for larger screens */
  }
}

/* Media queries for medium devices */
@media (min-width: 48rem) and (max-width: 61.9375rem) { /* 768px to 991px equivalent */
  .slider-container .slick-slide img {
    width: 100%;
    height: 18rem; /* Set a fixed height for the images (400px equivalent) */
    border-radius: 0.5rem; /* Rounded corners for the images */
    object-fit: contain; /* Ensure the entire image fits within the box */
  }

  .media-container {
    min-height: 300px; /* Set a minimum height for medium screens */
  }
}

/* Media queries for small devices */
@media (max-width: 47.9375rem) { /* 767px equivalent */
  .modal-content {
    padding: 1.25rem; /* Reduced padding for small devices */
    max-width: 100%; /* Full width for small devices */
  }

  .slider-container .slick-prev, .slider-container .slick-next {
    width: 1.25rem; /* 20px equivalent */
    height: 1.25rem; /* 20px equivalent */
  }

  .slider-container .slick-prev:before, .slider-container .slick-next:before {
    font-size: 1.25rem; /* 20px equivalent */
  }
  
  .slider-container .slick-slide img {
    width: 100%;
    height: 13rem; /* Set a fixed height for the images (400px equivalent) */
    border-radius: 0.5rem; /* Rounded corners for the images */
    object-fit: contain; /* Ensure the entire image fits within the box */
  }

  .media-container {
    min-height: 200px; /* Set a minimum height for the media container on small devices */
  }
}

/* ProjectSection.css */
.badge {
  cursor: pointer;
  margin: 0.2rem;
  background-color: #2a2a2a !important;
  color: white !important;
}

.popup-content {
  color: black;
}

.project-card-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: white;
  height: 32rem;
}

.project-card-large {
  height: 40rem;
}

/* Media query to adjust the height of project-card-large on small screens */
@media (max-width: 47.9375rem) { /* 767px equivalent */
  .project-card-large {
    height: 30rem; /* Match the height of project-card on small screens */
  }
}

.card-description {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Add gap between badges */
  margin-top: auto; /* Pushes the tags to the bottom */
  overflow: hidden; /* Hide overflow if necessary */
  max-height: 4.8rem; /* Optional: Limit the height */
}

.card-img-ratio {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.card-img-ratio img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire area */
}

.media-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Adjusted height */
  max-height: 100%;
}

.media-item {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer; /* Make it clear that the image is clickable */
}

.media-item.video {
  width: 100%;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is above other content */
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.fullscreen-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
