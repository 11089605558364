/* JobItem.css */

.job-item {
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transition for all properties */
    position: relative;
    border: 1px solid #e0e0e0;
    font-family: 'Onest', sans-serif;
    font-weight: 400;
    color: #ececec; /* Default text color */
    max-height: 190px; /* Set an initial max-height */
}

.job-item.expanded {
    max-height: none; /* Allow the box to grow fully when expanded */
    background-color: #1f1f1f;
    color: #f2f2f2; /* Change text color when expanded */
    padding-bottom: 20px;
}

.job-item h3 {
    font-family: 'Onest', sans-serif;
    font-weight: 800;
}

.job-item p {
    margin: 5px 0;
}

.job-item .description {
    margin: 10px 0;
}

.job-item .toggle-button {
    background-color: transparent;
    border: none;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 0;
}

.job-item .toggle-button:focus {
    outline: none;
}
