.header-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 1rem; /* Add some space below the header */
}

.header-container h3 {
    display: inline-block;
    border: 1px solid #fff; /* White border */
    border-radius: 2rem; /* Border radius */
    padding: 0.5rem 1rem; /* Minimal padding to fit the text */
    font-size: 1rem;
    background-color: #000; /* Match the background color to blend */
    z-index: 1;
    margin: 0; /* Remove default margin */
}

.header-container hr {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-top: 2px solid #fff;
    margin: 0; /* Remove default margin */
    z-index: 0;
}

.flex-container {
    display: flex;
    flex-direction: column; /* Default to column layout */
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start */
}

@media (min-width: 768px) {
    .flex-container {
        flex-direction: row; /* Switch to row layout on medium and larger screens */
    }
}

.flex-container ul {
    flex: 1;
    margin-right: 20px; /* Add some space between the list and the slider */
}

.media {
    flex: 1;
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Adjust the height as needed */
    padding-left: 1rem;
}

@media (min-width: 768px) {
    .media {
        width: 50%; /* Adjust the width as needed */
        height: 300px; /* Adjust the height as needed */
    }
}

.title{
    font-size: 3rem;
}