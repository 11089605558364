.link-preview-card {
    border: 1px solid #ddd;
    font-family: 'Onest', sans-serif;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .link-preview-anchor {
    text-decoration: none;
    color: inherit;
  }
  
  .link-preview-content {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .link-preview-image {
    width: 100px;
    height: auto;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .link-preview-text {
    flex-grow: 1;
  }
  
  @media (max-width: 768px) {
    .link-preview-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .link-preview-image {
      width: 75px; /* Smaller width for smaller devices */
      margin: 0 0 10px 0;
    }
  
    .link-preview-text {
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .link-preview-image {
      width: 5rem; /* Even smaller width for very small devices */
      margin: 0 0 10px 0;
    }
  }
  
  .onest-font {
    font-family: 'Onest', sans-serif;
  }
  