.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-regularoblique.otf') format('opentype');
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-mediumoblique.otf') format('opentype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-demibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-demiboldoblique.otf') format('opentype');
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-boldoblique.otf') format('opentype');
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-extraboldoblique.otf') format('opentype');
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./assets/fonts/Fontspring-DEMO-integralcf-heavyoblique.otf') format('opentype');
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url('./assets/fonts/Onest-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


body {
  font-family: 'Integral CF', sans-serif;
  color:white;
  background-color: black;
}

p {
  font-family: 'Onest', sans-serif;
  font-weight: 400; /* Adjust the weight if necessary */
}